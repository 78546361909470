@import '@/styles/styles';

.container {
    position: relative;

    p {
        margin: 0;
    }

    .box {
        align-items: center;
        display: flex;
        justify-content: left;
        opacity: 0;
        transition: max-height 0.5s ease-in-out, padding 0.3s ease-in-out;
        width: 100%;
        z-index: 1011;
        max-height: 0;
        top: var(--app-banner-height, 0px);
        position: relative;

        .background {
            background: rgb(0, 215, 225);
            background: linear-gradient(0deg, rgba(0, 215, 225, 1) 0%, rgba(0, 215, 225, 1) 100%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            margin-left: calc(50% - 50vw);
        }

        .main {
            display: grid;
            grid-template-columns: 1fr 30px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            width: 100%;

            .messageContent {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                width: 100%;
            }

            .closeButton {
                cursor: pointer;
                display: flex;
                margin: auto;
                margin-right: unset;
                .closeIcon {
                    height: 14px;
                    position: relative;
                    width: 14px;
                    &::before,
                    &::after {
                        background: black;
                        border-radius: 1.5px;
                        bottom: -0.5px;
                        content: '';
                        height: 17px;
                        position: absolute;
                        right: 5.5px;
                        transform-origin: center center;
                        width: 2px;
                    }
                    &::before {
                        transform: rotate(45deg);
                    }
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    &.haveContent {
        .box {
            max-height: 500px;
            opacity: 1;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media only screen and (max-width: 768px) {
        .box {
            .messageContent {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}
